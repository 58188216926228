.carousel {

	.carousel-button {
		background-color: transparent;
		background-repeat: no-repeat;
		background-position: center;
		box-shadow: 0 0 10px 10px #F5F5F5;
		margin-top: -6px;
		z-index: 1;

		&:hover {
			background-color: transparent;
			color: rgb(75, 75, 75);
		}

		&.next {
			background-image: url("../../assets/images/icon_arrow_right.svg");
		}

		&.prev {
			background-image: url("../../assets/images/icon_arrow_left.svg");
		}

		&.no-shadow {
			box-shadow: none;
		}
	}

	.carousel-item {
		background-color: #ffffff;
		box-shadow: 0 0 10px rgba(75, 75, 75, 0.2);
		border-radius: 10px;
		height: 100px;
		padding: 15px 75px;

		.carousel-item-logo {
			width: 100%;
			height: 100%;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
}

