//=====================================
// Spotted v2 (style used by multiple components and/or pages)
//======================================
// Fonts

@font-face {
	font-family: "WayFinder";
	src: local("WayFinder"), url("./assets/fonts/Wayfinder-Bold.otf") format("OpenType");
}

@font-face {
	font-family: "TTNorms-Pro-Regular";
	src: local("TTNorms-Pro-Regular"), url("./assets/fonts/TTNorms-Pro-Regular.ttf") format("TrueType");
}

@font-face {
	font-family: "TTNorms-Pro-Bold";
	src: local("TTNorms-Pro-Bold"), url("./assets/fonts/TTNorms-Pro-ExtraBold.otf") format("OpenType");
}


//General
html {
	scroll-behavior: smooth;
}

body {
	font-family: "TTNorms-Pro-Regular", sans-serif;
	font-weight: 400;
	background-color: #F5F5F5;
	overflow-x: hidden;
}

//Styles
.whiteButton {
	font-family: "TTNorms-Pro-Bold", serif;
	color: #4b4b4b;
	background-color: #ffffff;
	text-transform: none;
	border-radius: 90px;
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
	padding: 15px 30px;
	box-shadow: 0 0 10px rgba(75, 75, 75, 0.3);

	&:hover {
		background-color: #66FFA3;
	}
}

.darkButton {
	font-family: "TTNorms-Pro-Bold", serif;
	color: #ffffff;
	background-color: #4b4b4b;
	text-transform: none;
	border-radius: 90px;
	font-size: 16px;
	font-weight: 400;
	line-height: 30px;
	text-align: center;
	min-width: 210px;
	padding: 15px;
	box-shadow: 0 0 10px rgba(75, 75, 75, 0.3);

	&:hover {
		background-color: #66FFA3;
		color: #4b4b4b;
	}

	a {
		color: inherit;
		text-decoration: none;
	}
}

.steps {
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(74px);
	border-radius: 15px;
	font-style: normal;
	font-weight: normal;
	color: #4b4b4b;
	padding: 30px;

	& .number {
		font-family: "TTNorms-Pro-Bold", serif;
		font-size: 20px;
		line-height: 30px;
		border: 1px solid #4b4b4b;
		box-sizing: border-box;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		text-align: center;
	}

	& .title {
		font-size: 30px;
		line-height: 30px;
		padding: 36px 0 10px 0;
	}

	& .body {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 30px;
	}
}


.benefits {
	background: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(74px);
	border-radius: 15px;

	& .benefit {
		color: #4b4b4b;
		font-size: 16px;
	}
}

.percent {
	font-size: 85%;
}

strong {
	font-family: "TTNorms-Pro-Bold", sans-serif;
	font-weight: 400;
}

blockquote {
	font-family: "WayFinder", serif;
	font-size: 40px;
	line-height: 40px;
	color: #66FFA3;
	position: relative;
	margin: 0 0 15px 0;
	padding: 0;

	&::before {
		content: "«";
		position: absolute;
		top: 0;
		left: -0.5em;
	}

	&::after {
		content: "»";
		padding-left: 0.1em;
	}

	h4 {
		display: inline;
	}

	p {
		display: inline-block;
		margin-top: 0;
		margin-bottom: 10px;

		&:last-child {
			display: inline;
			margin-bottom: 0;
		}
	}
}