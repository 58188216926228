.trapezoid_left {
  position: relative;
  background-clip: border-box;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
}
.slogo {
  margin-left: -60px;
  border: solid #F5F5F5 15px;
  border-radius: 50%;
}
