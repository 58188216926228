.kpi {
  background-position: center;
  background-size: cover;
  background-clip: border-box;
  background-repeat: no-repeat;
  border-radius: 25px 25px 0 0;
}
.green_box {
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(74px);
  border-radius: 15px;
  font-size: 18px;
  line-height: 28px;
  width: 100%;

  & .number {
    font-family: "TTNorms-Pro-Bold", serif;
    font-size: 20px;
    line-height: 30px;
    border: 1px solid #4b4b4b;
    box-sizing: border-box;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    text-align: center;
  }
}
