.footer {
  background-color: #4B4B4B;
  color: #F4F4F4;

  & .footer_logo {
    height: 20px;
    display: flex;
    line-height: 22px;
    vertical-align: text-bottom !important;
  }
}
