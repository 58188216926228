.confirmation {
	background: white;
	border-radius: 15px;
	width: 100%;
	margin: 24px auto 0;
}

.confirmation .title {
	margin-top: 16px;
	margin-bottom: 16px;
}
