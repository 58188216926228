.contact-form {
  .contact-form-field {
    text-align: left;
    & input {
      background-color: #ffffff;
      border-radius: 10px;
    }
    & textarea {
      background-color: #ffffff;
      border-radius: 10px;
      margin: 25px 10px;
    }
    & .MuiFilledInput-root {
      padding: 0 15px;
      border-radius: 10px;
      background-color: #ffffff;
      z-index: 1;
      &::before, &::after {
        border: none;
      }
      &.Mui-error {
        border: 2px solid #EB5757;
      }
    }
    & .MuiSelect-select {
      background-color: #ffffff;
      border-radius: 10px;
    }
    & .MuiCheckbox-root {
      padding: 0;
      margin: 10px;
      background: #f4f4f4;
      border: 1px solid #4b4b4b;
      box-sizing: border-box;
      border-radius: 5px;
      color: #f4f4f4;
      &.Mui-checked {
        background-color: #f4f4f4;
        color: #4b4b4b;
      }
    }
    & a {
      font-family: "TTNorms-Pro-Bold";
      font-weight: 800;
      text-decoration: none;
      font-size: 16px;
      line-height: 22px;
      color: #4b4b4b;
      &:visited {
        text-decoration: none;
        color: #4b4b4b;
      }
    }
    & .MuiFormHelperText-root.Mui-error {
      padding: 25px 25px 15px 25px;
      z-index: 0;
    }
  }
  .submitButton {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(75, 75, 75, 0.3);
    border-radius: 90px;
    padding: 15px 60px;
    font-family: "TTNorms-Pro-Bold";
    font-size: 16px;
    text-transform: none;
    &:hover {
      background-color: #4b4b4b;
      color: #ffffff;
    }
  }

  .form-checkbox-helper-text {
    border-radius: 10px;
    margin: 5px 0 !important;
    padding: 20px 30px !important;
  }
}
