.navigation_menu {
  & .menu_logo_link {
    display: block;
  }
  & .menu_logo {
    pointer-events: none;
    @media (min-width: 0px) {
	  max-width: 160px;
	}
	@media (min-width: 1000px) {
	  max-width: 210px;
	}
  }
  & .menu_link {
    font-size: 16px;
    line-height: 60px;
    text-align: center;
    color: #484848;
    & a {
      text-decoration: none;
      &:link {
        color: #484848;
      }
      &:visited {
        color: #484848;
      }
      &:active {
        color: #484848;
      }
      &.active {
        font-family: "TTNorms-Pro-Bold", sans-serif;
      }
    }
  }
  & .menu_button {
    padding: 0 20px;
    margin: 0 10px;
    min-width: 210px;
    line-height: 60px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 90px;
    & p a {
      text-decoration: none;
      font-size: 16px;
      color: #484848;
    }
    &:link {
      color: #484848;
    }
    &:visited {
      color: #484848;
    }
    &:active {
      color: #484848;
    }
  }
}

.navigation_menu_responsive {
  display: flex;
}
.mobile_menu_responsive {
  display: none;
}

@media (max-width: 1000px) {
  .navigation_menu_responsive {
    display: none;
  }
  .mobile_menu_responsive {
    display: flex;
  }
}

.navigation_menu_mobile_wrapper {
  width: 100%;
  max-width: 500px;
  background-color: #4b4b4b;
}

.mobile_menu {
  background-color: #4b4b4b;
  font-size: 18px;
  line-height: 26px;
  width: 100%;
  color: #ffffff;

  .mobile_menu_item {
    border-bottom: 1px solid #ffffff1c;
    padding: 0;

    &.indent {
      text-indent: 20px;
    }

    a {
      text-decoration: none;
      color: #ffffff;
      padding: 20px;
      width: 100%;

      &:hover {
        background-color: #e5e5e51c;
      }

      &.active {
        background-color: #e5e5e5;
        color: #484848;
      }
    }
  }

  .mobile_menu_close {
    border-bottom: 1px solid #ffffff1c;
    justify-content: flex-end;
    padding-right: 40px;
  }
}
