.trapezoid_right {
	position: relative;
	background-clip: border-box;
	background-repeat: no-repeat;
	width: 100%;
	display: flex;
}

.side_image {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	filter: drop-shadow(0px 0px 10px rgba(75, 75, 75, 0.3));
}