.services {
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  min-height: 700px;
  border-radius: 25px;

  & .service_box {
    font-weight: 400;
    padding: 30px 15px;
    text-align: center;
    color: #4b4b4b;
    box-shadow: 0px 0px 10px rgba(75, 75, 75, 0.2);
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(74px);
  }
  & .service_number {
    font-size: 20px;
    line-height: 45px;
    background-color: #ffffff;
    border-radius: 50%;
    width: 45px;
    height: 45px;
  }

  & .service_link {
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: #4b4b4b;
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(75, 75, 75, 0.2);
    border-radius: 90px;
    padding: 15px 60px;
    &:hover {
      background-color: #66FFA3;
    }
    & p {
      text-decoration: none;
      color: #4b4b4b;

      &:link {
        text-decoration: none;
        color: #4b4b4b;
      }
      &:visited {
        text-decoration: none;
        color: #4b4b4b;
      }
      &:active {
        text-decoration: none;
        color: #4b4b4b;
      }
    }
  }
}
