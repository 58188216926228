.error_dialog {

  .MuiPaper-root {
    border-radius: 15px;
    background-color: #000000;
    box-shadow: 3px 0 10px rgba(0, 0, 0, 0.5);
    color: white;
  }

  .MuiDialogContentText-root {

    font-family: "TTNorms-Pro-Regular", sans-serif;
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    color: white;

    & a {
      font-family: "TTNorms-Pro-Bold", sans-serif;
      text-decoration: none;
      font-size: 16px;
      line-height: 22px;
      color: #f4f4f4;

      &:visited {
        text-decoration: none;
        color: #f4f4f4;
      }

      a:focus {
        color: #f4f4f4;
      }

      &:hover {
        color: #f4f4f4;
      }
    }
  }

  .MuiDialogActions-root {
    padding-bottom: 8px;
    margin-top: 8px;
    margin-right: 0;

    & button {
      background-color: #ffffff;
      color: #484848;
      font-family: "TTNorms-Pro-Bold", sans-serif;
      text-transform: none;
      font-size: 16px;
      line-height: 22px;
      margin-left: 10px;
      padding: 5px 20px;
      border-radius: 15px;

      &:hover {
        background-color: #66FFA3;
        color: #4b4b4b;
      }

      &.MuiButton-textSecondary:hover {
        background-color: #EEFF84;
        color: #4b4b4b;
      }
    }
  }
}
