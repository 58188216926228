.floating-top-button {
	position: fixed;
	background-color: #4b4b4b;
	box-shadow: 0 0 10px 0 rgba(75, 75, 75, 0.3) !important;
	color: #F4F4F4;

	&.MuiFab-primary:hover {
		background-color: #66FFA3;
		color: #4B4B4B;
	}

	&.MuiFab-secondary:hover {
		background-color: #EEFF84;
		color: #4B4B4B;
	}
}