.team {
  border-radius: 15px;
  overflow: hidden;
  background: #333;
  box-shadow: #111 inset 0 0 150px;

  .team-member {
    padding: 50px 30px;
    text-align: center;
    color: #fff;
    text-shadow: #333 1px 1px 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    aspect-ratio: 1;
  }
}
